body{
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
}

img {
  max-width: 100%;
  border-radius: 5px;
}

p {
  font-size: 13px;
}

h4 {
  margin: 0;
}

/* bio */

.bio {
  display: grid;
  grid-template-columns: 1fr 3fr;
  background: linear-gradient(135deg, rgb(72, 180, 144), white);
  padding: 5%;
}



.portrait {
  object-fit: contain;
}

.about-me-write-up {
  padding: 2%;
  border: .25 solid rgb(209, 209, 209);
  box-shadow: 5px 5px 5px grey;
  border-radius: 5px;
  background-color: white;
}

.write-up{
  text-align: justify;
}

/* projects */

.projects{
  display: absolute;
  padding: 2%;
}

.projects-display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
}

.project-card {
  display: grid;
  grid-template-rows: 1fr 1fr;
  max-width: 250px;
  border: .25px rgb(218, 218, 218) solid;
  border-radius: 5px;
  box-shadow: 5px 5px 5px grey;
  margin: 2%;
  justify-self: center;
  height: 100%;
  background-color: white;
}

.project-image {
  border-radius: 5px;
  object-fit: contain;
}


.project-details {
  padding: 1%;
  display: grid;
  grid-template-rows: .25fr .75fr;
}

.title-and-links {
  display: grid;
  grid-template-columns: .75fr .25fr;
}

.project-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 2%;
}

.links{
  object-fit: contain;
  width: 33%;
  padding: 2%;
}

.description{
  padding-left: 2%;
  padding-right: 2%;
  text-align: justify;
}

/* contact */

.contacts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2%;
  background: linear-gradient(rgb(72, 180, 144), white);
}

.contact-info {
  border: .25 solid rgb(209, 209, 209);
  box-shadow: 5px 5px 5px grey;
  border-radius: 5px;
  background-color: white;
  padding: 2%;
  height: 50%;
}

.contact-photo {
  object-fit: contain;
  padding: 2%;
}

@media (max-width: 600px) {
  .bio {
    grid-template-columns: 1fr;
  }
  .portrait {
    display: flex;
    flex-direction: center;
  }
  
  .contacts {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1100px) {
  .projects-display{
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-row-gap: 30px;
  }
}

@media (max-width: 600px) {
  .projects-display{
    grid-template-columns: 1fr;
    align-items: center;
    grid-row-gap: 30px;
  }
}

.my-links {
  display: flex;
  flex-direction: row;
  width: 25%;
}

